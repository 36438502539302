import React, { useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { ReactComponent as CloseIcon } from "../../assets/icon/CloseIcon.svg";
import useStore from "../../store";
import BasketElements from "./BasketElements";
import BasketForm from "./BasketForm";
import { DISABLE_PAYMENT } from "../../lib/constants/constants";
import { v4 as uuidv4 } from "uuid";

const Basket = ({ handleChangeVisible }) => {
  const { data, removeAll } = useStore();
  const [state, setState] = useState(false);

  const totalPrice = data.reduce(
    (accumulator, item) => accumulator + Number(item.price) * item.quantity,
    0,
  );

  const handleClick = (e) => {
    if (e.target.classList.contains("OutterSC")) {
      handleChangeVisible();
    }
  };

  const handleNextStep = () => {
    setState(!state);
  };

  return createPortal(
    <OutterSC className={"OutterSC"} onClick={handleClick}>
      {!state && (
        <>
          {data.length > 0 && (
            <ContainerSC>
              <ScrollContainerSC>
                <TopContainerSC>
                  <TextContainerSC>
                    <TitleSC>Корзина</TitleSC>
                    <RemoveAll onClick={removeAll}>Удалить всё</RemoveAll>
                  </TextContainerSC>
                  <IconContainerSC onClick={handleChangeVisible}>
                    <CloseIcon />
                  </IconContainerSC>
                </TopContainerSC>
                <BasketElements data={data} />
              </ScrollContainerSC>
              <ItogoSC>
                <SubtitleSC>Итого:</SubtitleSC>
                <TitleSC>{totalPrice} ₽</TitleSC>
              </ItogoSC>

              {DISABLE_PAYMENT === "true" ? (
                <OrderButtonSC onClick={handleNextStep}>Заказать</OrderButtonSC>
              ) : (
                <form
                  method="POST"
                  action="https://best-part.server.paykeeper.ru/create/"
                  acceptCharset="utf-8"
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "center",
                  }}>
                  <input
                    style={{ display: "none" }}
                    type="text"
                    name="sum"
                    value={totalPrice}
                  />
                  <input
                    style={{ display: "none" }}
                    type="text"
                    name="orderid"
                    value={uuidv4()}
                  />
                  <input
                    style={{ display: "none" }}
                    type="text"
                    name="user_result_callback"
                    value={"https://best-part.site/"}
                  />
                  <OrderButtonSC type="submit" disabled={data.length < 1}>
                    Оплатить
                  </OrderButtonSC>
                </form>
              )}
            </ContainerSC>
          )}
          {!data.length && (
            <ContainerSC>
              <TopContainerSC>
                <IconContainerSC onClick={handleChangeVisible}>
                  <CloseIcon />
                </IconContainerSC>
              </TopContainerSC>
              <EmptyWrapperSC>
                <TitleSC>Корзина пуста</TitleSC>
              </EmptyWrapperSC>
            </ContainerSC>
          )}
        </>
      )}
      {state && (
        <ContainerSC>
          <BasketForm handleChangeVisible={handleChangeVisible} />
        </ContainerSC>
      )}
    </OutterSC>,
    document.querySelector("#root") as HTMLElement,
  );
};

const SubtitleSC = styled("p")`
  font-size: 14px;
  color: #000;
  font-weight: 700;
`;

const ItogoSC = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ScrollContainerSC = styled("div")`
  flex-grow: 1;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const OrderButtonSC = styled("button")`
  max-width: 70%;
  margin: 16px auto 0;

  border-radius: 16px;

  background: #75919d;
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
`;

const OutterSC = styled("div")`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.5);

  z-index: 5000;
`;

const ContainerSC = styled("div")`
  width: 50%;
  height: 70%;

  overflow-y: scroll;

  border-radius: 16px;
  padding: 16px;
  background: #fff;

  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  @media (max-width: 678px) {
    width: 90%;
    height: 70%;
  }

  &::-webkit-scrollbar {
    width: 8px; /* Ширина скроллбара */
  }
`;

const TopContainerSC = styled("div")`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

const TextContainerSC = styled("div")`
  display: flex;
  flex-direction: column;
`;

const TitleSC = styled("h5")`
  font-size: 16px;
`;

const IconContainerSC = styled("div")`
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const RemoveAll = styled("button")`
  cursor: pointer;

  background: none;

  font-size: 16px;
`;

const EmptyWrapperSC = styled("div")`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default React.memo(Basket);
