import styled from "styled-components";

const FooterSC = styled.footer`
  /* height: 5rem; */
  color: #fff;

  background: #45565c;

  padding: 1.69rem 3.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const SpanSC = styled.span`
  text-transform: uppercase;

  color: #fff;

  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;

  text-align: center;

  p {
    cursor: pointer;
  }

  @media (max-width: 970px) {
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

const ContainerSC = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 6rem;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 1270px) {
    gap: 3rem;
  }

  @media (max-width: 1060px) {
    gap: 1rem;
  }
`;

const TextContainerSC = styled.div`
  padding: 0 8px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #fff;
  background: #45565c;
`;

const SubtitleSC = styled.p`
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 489px) {
    text-align: center;
  }
`;

const ImageContainerSC = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    max-width: 520px;
  }

  @media (max-width: 575px) {
    img {
      max-width: 375px;
    }
  }

  @media (max-width: 375px) {
    img {
      max-width: 325px;
    }
  }

  @media (max-width: 320px) {
    img {
      max-width: 315px;
    }
  }
`;

export const useFormStyles = () => ({
  FooterSC,
  SpanSC,
  ContainerSC,
  TextContainerSC,
  SubtitleSC,
  ImageContainerSC,
});
