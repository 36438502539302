// @ts-nocheck
import { useEffect, useState } from "react";

import { useAboutCompanyStyles } from "./AboutCompany.styles";

const AboutCompany = ({ isOpenAbout }) => {
  const [isOpacity, setIsOpacity] = useState(true);
  const [text, setText] = useState("Specialized Machinery Parts");
  const [isOpenForm, setIsOpenForm] = useState(false);

  const closeForm = () => {
    setIsOpenForm(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpacity(false);

      // После завершения анимации изменяем текст и показываем его снова
      setTimeout(() => {
        setText("Детали специализированного оборудования");
        setIsOpacity(true);
      }, 500); // Задержка, чтобы анимация успела завершиться
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <TextContainerSC>
      <ContainerSC>
        <DescriptionCompanySC isOpenAbout={isOpenAbout}>
          Продаём строительные инструменты. Вы&nbsp;можете приобрести
          инструменты как по&nbsp;отдельности, так и&nbsp;целыми наборами. Наши
          товары качественные, и&nbsp;на&nbsp;них распространяется гарантия.
        </DescriptionCompanySC>
        <RightContainerSC>
          <OpacityCompanySC
            isOpacity={isOpacity}
            text={text === "Детали специализированного оборудования"}>
            {text}
          </OpacityCompanySC>
        </RightContainerSC>
      </ContainerSC>
    </TextContainerSC>
  );
};

const {
  TextContainerSC,
  RightContainerSC,
  OpacityCompanySC,
  DescriptionCompanySC,
  TitleCompanySC,
  ContainerSC,
} = useAboutCompanyStyles();

export default AboutCompany;
