import { useHeaderStyle } from "./HeaderStyles";

import { ReactComponent as CartIcon } from "../../assets/icon/CartIcon.svg";

import { useHeader } from "./useHeader";

import AboutCompany from "components/AboutCompany";
import { useEffect, useState } from "react";
import Basket from "../Basket";
import styled from "styled-components";
import Requisites from "./Requisites/Requisites";
import { DISABLE_PAYMENT } from "../../lib/constants/constants";
import LegalInfo from "./LegalInfo/LegalInfo";

type ModalState = "requisites" | "legalInfo" | "";

const Header = () => {
  const {
    totalCount,
    isOpenContact,
    toggleContact,
    closeForm,
    isOpenAbout,
    toggleAbout,
    isOpenInvisibleText,
    toggleInvisibleText,
    closeBurger,
  } = useHeader();

  const [basketVisible, setBasketVisible] = useState(false);

  const [requisites, setRequisites] = useState<ModalState>("");
  const [legalInfo, setLegalInfo] = useState<ModalState>("");

  const handleRequisites = (data: ModalState) => {
    return () => {
      setRequisites(data);
    };
  };

  const handleLegalInfo = (data: ModalState) => {
    return () => {
      setLegalInfo(data);
    };
  };

  const handleChangeVisible = () => {
    setBasketVisible(!basketVisible);
  };
  const handleChangeVisibleMobile = () => {
    setBasketVisible(!basketVisible);
    toggleContact();
  };

  useEffect(() => {
    document.body.style.overflow = basketVisible ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [basketVisible]);

  return (
    <>
      <IconContainerSC onClick={handleChangeVisible}>
        <CartIcon />
        <SpanCountSC>{totalCount}</SpanCountSC>
      </IconContainerSC>

      <HeaderSC>
        <ButtonSC onClick={toggleContact} />

        <WrapperHeaderSC>
          <ContainerSC>
            <SpanSC onClick={toggleAbout} $isOpen={isOpenAbout}>
              <p>О КОМПАНИИ</p>
            </SpanSC>
            <SpanSC onClick={() => setRequisites("requisites")}>
              <p>ПОКУПАТЕЛЯМ</p>
            </SpanSC>
            <SpanSC>
              <a href="#formTitle">обратная связь</a>
            </SpanSC>
            <SpanSC>
              <a href="#Products">Продукция</a>
            </SpanSC>
            <SpanSC onClick={() => setLegalInfo("legalInfo")}>
              <p>Правовая информация</p>
            </SpanSC>
          </ContainerSC>
          <ContainerSC>
            <SpanSC>
              <a href="tel:+79823578517">+7 (982) 357-85-17</a>
            </SpanSC>
            <SpanSC>
              <a href="mailto:NikolyaRebrik@yandex.ru">
                NikolyaRebrik@yandex.ru
              </a>
            </SpanSC>
          </ContainerSC>
        </WrapperHeaderSC>
      </HeaderSC>
      {isOpenContact && (
        <FullScreenMenu>
          <ULSC>
            <LISC>
              <SpanSC
                onClick={toggleInvisibleText}
                $isOpen={isOpenInvisibleText}>
                <p>О КОМПАНИИ</p>
              </SpanSC>
            </LISC>
            <InvisibleText $isOpen={isOpenInvisibleText}>
              Продаём строительные инструменты. Вы можете приобрести инструменты
              как по отдельности, так и целыми наборами. Наши товары
              качественные, и на них распространяется гарантия.
            </InvisibleText>
            {/* <LISC onClick={closeBurger}>Обратная связь</LISC> */}
            <LISC onClick={() => setRequisites("requisites")}>
              <p>ПОКУПАТЕЛЯМ</p>
            </LISC>
            <LISC onClick={() => setLegalInfo("legalInfo")}>
              <p>Правовая информация</p>
            </LISC>
            <LISC onClick={closeBurger}>
              <a href="#formTitle">Обратная связь</a>
            </LISC>
            <LISC onClick={closeBurger}>
              <a href="#Products">продукция</a>
            </LISC>
            <LISC>
              <SpanSC>
                <a href="tel:+79823578517">+7 (982) 357-85-17</a>
              </SpanSC>
            </LISC>
            <LISC>
              <SpanSC>
                <a href="mailto:NikolyaRebrik@yandex.ru">
                  NikolyaRebrik@yandex.ru
                </a>
              </SpanSC>
            </LISC>
            <LISC>
              <SpanSC onClick={handleChangeVisibleMobile}>
                <p>Корзина</p>
              </SpanSC>
            </LISC>
          </ULSC>
          <ParagraphSC>
            Офис: Молодогвардейцев 31А, 3 этаж каб. 304 <br />
            Склад: Троицкий тракт, 21г, корпус 3
          </ParagraphSC>
        </FullScreenMenu>
      )}
      <AboutCompany isOpenAbout={isOpenAbout} />
      {basketVisible && <Basket handleChangeVisible={handleChangeVisible} />}
      <Requisites
        isOpen={requisites === "requisites"}
        handleClose={handleRequisites}
        className="req"
      />
      <LegalInfo
        isOpen={legalInfo === "legalInfo"}
        handleClose={handleLegalInfo}
        className="info"
      />
    </>
  );
};

const IconContainerSC = styled("button")`
  position: fixed;

  cursor: pointer;
  background: none;

  svg {
    width: 32px;
    height: 32px;
    fill: #fff;
  }

  z-index: 498;

  top: 80px;
  left: 20px;

  width: 46px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  @media (max-width: 600px) {
    top: 60px;
    left: 20px;
  }
`;

const {
  HeaderSC,
  WrapperHeaderSC,
  ContainerElementsSC,
  HeaderContactsSC,
  SpanSC,
  ButtonSC,
  ContainerSC,
  FullScreenMenu,
  ParagraphSC,
  ULSC,
  LISC,
  InvisibleText,
  SpanCountSC,
} = useHeaderStyle();

export default Header;
