import styled from "styled-components";

const SectionSC = styled.section`
  display: block;

  margin: 0 auto;

  color: #fff;

  z-index: 300;
  padding: 0 8px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const TitleSC = styled("h4")`
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  margin-bottom: 1rem;
  padding-top: 20rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
`;

const FormSC = styled("form")`
  display: flex;

  gap: 1rem;

  align-items: center;
  justify-content: center;

  margin: 0 auto 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InputSC = styled("input")`
  height: 3rem;
  width: 100%;
  max-width: 18.75rem;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2.5px);

  padding: 0.75rem 1.5rem;
  border: none;

  color: #000;
  text-overflow: ellipsis;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);

    font-weight: 600;
    line-height: 1.25rem;

    font-size: 0.875rem;
    font-style: normal;
  }

  @media (max-width: 768px) {
    max-width: none;
  }
`;

const ButtonSC = styled("button")`
  background: rgba(69, 86, 92, 0.5);
  backdrop-filter: blur(2.5px);

  width: 100%;
  max-width: 15rem;
  height: 3.25rem;

  color: #fff;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  cursor: pointer;

  &:disabled {
    cursor: initial;
    background: gray;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
`;

const ExitButton = styled("button")`
  position: absolute;

  width: 2rem;
  height: 2rem;

  cursor: pointer;

  background: none;
  background-image: url("ExitButton.svg");
  background-repeat: no-repeat;
`;

export const useFormFeedBackStyles = () => ({
  SectionSC,
  FormSC,
  TitleSC,
  InputSC,
  ButtonSC,
  ExitButton,
});
