import styled from "styled-components";

const CardSC = styled("article")`
  width: 100%;
  height: 25rem;
  //width: 296px;
  cursor: default;

  color: #fff;

  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  -webkit-tap-highlight-color: transparent;

  @media (max-width: 922px) {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    height: 334px;
  }
`;

const ImgSC = styled("img")`
  display: block;
  width: 160px;
  height: 160px;

  border-radius: 50%;
  /* object-fit: contain; */

  /* width: 13rem; */
  /* height: 15rem; */

  /* width: 100%; */

  margin-bottom: 1.5rem;

  @media (max-width: 562px) {
    /* width: 90%; */
    width: 9rem;
    height: 9rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 562px) {
    /* width: 90%; */
    width: 7rem;
    height: 7rem;
  }
`;

const InfoContainerSC = styled("div")`
  //firefox
  scrollbar-width: thin;
  scrollbar-color: #e4e6eb transparent;

  flex-grow: 1;

  &::-webkit-scrollbar {
    width: 8px; /*Ширина скроллбара*/
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e4e6eb;
    height: 26px; /* Высота ползунка */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  overflow-y: scroll;
  //overflow-x: hidden;

  @media (max-width: 922px) {
    overflow-y: hidden;
  }
`;

const NumberContainerSC = styled("div")`
  display: flex;
  justify-content: center;

  width: 100%;

  padding: 5px;

  align-items: center;

  @media (max-width: 452px) {
    justify-content: center;
  }
`;

const TitleSC = styled("h5")`
  margin-bottom: 0.5rem;

  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  text-align: center;

  @media (max-width: 560px) {
    text-align: left;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DescriptionSC = styled("p")`
  text-align: center;

  overflow-y: auto;
  margin: 0 auto;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;

  padding-right: 10px;

  width: 100%;

  word-break: break-word;

  @media (max-width: 560px) {
    text-align: left;

    font-size: 0.775rem;
    font-style: normal;
    font-weight: 600;
    max-width: 170px;
    line-height: 1.25rem;
    //word-break: normal;

    //display: -webkit-box;
    //-webkit-line-clamp: 6;
    //-webkit-box-orient: vertical;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
`;

const VendorCodeSC = styled("p")`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;

  @media (max-width: 452px) {
    display: none;
  }
`;

const PriceSC = styled("p")`
  text-align: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const useProductCardStyles = () => ({
  CardSC,
  ImgSC,
  InfoContainerSC,
  NumberContainerSC,
  TitleSC,
  DescriptionSC,
  VendorCodeSC,
  PriceSC,
});
