import styled from "styled-components";

const TextContainerSC = styled("div")`
  z-index: 10;
  position: absolute;

  display: flex;

  user-select: none;
  top: 6.25rem;

  color: #fff;

  padding: 0 40px;

  width: 100%;

  @media (max-width: 1220px) {
    //width: 100%;
  }

  @media (max-width: 1305px) {
    top: 15px;
    right: 0;
  }

  @media (max-width: 442px) {
    padding: 0 16px;
  }
`;

const TitleCompanySC = styled("h1")`
  font-size: 28.514px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2.75rem;
    font-weight: 700;
    line-height: 3.4375rem;
  }

  @media (max-width: 375px) {
    font-size: 2.1875rem;
    font-weight: 700;
    line-height: 2.8125rem;
  }
`;

const ContainerSC = styled("div")`
  display: flex;

  justify-content: space-between;

  width: 100%;

  @media (max-width: 1326px) {
    padding-left: 0;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 1305px) {
    justify-content: end;
  }

  @media (max-width: 442px) {
    justify-content: end;
    padding-bottom: 4.8rem;
  }
`;

const DescriptionCompanySC = styled.p<{ isOpenAbout?: boolean }>`
  margin-bottom: 2.5rem;

  text-align: center;

  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  margin-left: 3.5rem;
  padding-right: 1.5rem;

  transition: all 0.6s linear;

  max-width: 34.5rem;

  opacity: ${({ isOpenAbout }) => (isOpenAbout ? 1 : 0)};

  @media (max-width: 1305px) {
    display: none;
  }
`;

const ButtonSC = styled("button")`
  width: 17.5rem;
  height: 4rem;

  color: #fff;

  cursor: pointer;

  border-radius: 56px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2.5px);

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  @media (max-width: 1326px) {
    margin: 0 auto;
  }
`;

const RightContainerSC = styled.div`
  display: flex;
  flex-direction: column;

  text-align: right;

  @media (max-width: 1305px) {
    width: 100%;
    max-width: 515px;
  }

  @media (max-width: 600px) {
    max-width: 340px;
  }

  @media (max-width: 442px) {
    max-width: 220px;
  }
`;

const OpacityCompanySC = styled.p<{ isOpacity: boolean; text: boolean }>`
  font-size: 28px;
  font-weight: 700;
  line-height: 38.019px;
  text-transform: uppercase;
  font-style: normal;

  transition: all 0.6s ease-in-out;

  opacity: ${({ isOpacity }) => (isOpacity ? 1 : 0)};

  @media (max-width: 1305px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 442px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const useAboutCompanyStyles = () => ({
  TextContainerSC,
  TitleCompanySC,
  DescriptionCompanySC,
  OpacityCompanySC,
  RightContainerSC,
  ContainerSC,
  ButtonSC,
});
