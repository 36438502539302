import { useMainStyles } from "./MainStyles";

import AboutCompany from "components/AboutCompany";
import Products from "components/Products";
import FormFeedBack from "components/FormFeedBack";
import FullVisionCard from "components/ProductCard/FullVision";

// ? убрал круг, пока без него верстаем

const Main = () => {
  return (
    <MainSC>
      <MainWrapperSC>
        <FormFeedBack />
      </MainWrapperSC>
      <Products />
    </MainSC>
  );
};

const {
  MainSC,
  MainWrapperSC,
  CircleWrapperSC,
  CircleSC,
  CircleColorSC,
  OuterSC,
} = useMainStyles();

export default Main;
