import { FormProvider } from "react-hook-form";

import { useFormFeedBackStyles } from "./FormFeedBackStyles";
import { useFormFeedback } from "./useFormFeedBack";
import React from "react";
import useStore from "../../store";

const FormFeedBack = () => {
  const { method, text, formData, handleSubmit, handleChange } =
    useFormFeedback();
  const { legal } = useStore();
  const { setLegal } = useStore();
  return (
    <SectionSC>
      <TitleSC id="formTitle">{text}</TitleSC>
      <FormProvider {...method}>
        <FormSC action="#" onSubmit={handleSubmit}>
          <InputSC
            name="name"
            type="text"
            placeholder="Ф.И.О. *"
            required
            autoComplete="none"
            value={formData.name}
            onChange={handleChange}
          />
          <InputSC
            name="contact"
            type="text"
            placeholder="E-mail / Телефон *"
            required
            autoComplete="none"
            value={formData.contact}
            onChange={handleChange}
          />
          <InputSC
            name="subject"
            type="text"
            placeholder="Тема запроса *"
            autoComplete="none"
            value={formData.subject}
            onChange={handleChange}
          />
          <ButtonSC type="submit" disabled={!legal}>
            Отправить
          </ButtonSC>
        </FormSC>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "8px",
            marginBottom: "2.8rem",
          }}>
          <input
            type="checkbox"
            id="legalInfo"
            defaultChecked={legal}
            onClick={() => setLegal(!legal)}
            style={{ cursor: "pointer", width: "16px" }}
          />
          <span style={{ textAlign: "center" }}>
            Согласен на обработку персональных данных
          </span>
        </div>
      </FormProvider>
    </SectionSC>
  );
};

const { SectionSC, FormSC, TitleSC, InputSC, ButtonSC, ExitButton } =
  useFormFeedBackStyles();

export default FormFeedBack;
