import styled, { css } from "styled-components";

const HeaderSC = styled("header")`
  width: 100%;
  max-width: 2048px;
  position: absolute;

  top: 2rem;
  /* right: 2rem; */

  z-index: 500;
`;

const WrapperHeaderSC = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto;
  width: 95%;

  @media (max-width: 1305px) {
    display: none;
  }
`;

const ContainerElementsSC = styled("div")`
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;

  color: #fff;
`;

const HeaderContactsSC = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 0 72px;
  align-items: center;

  margin-left: auto;

  @media (max-width: 820px) {
    gap: 0 2.19rem;
  }
`;

const SpanSC = styled.span<{ $isOpen?: boolean }>`
  text-transform: uppercase;

  color: #fff;

  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;

  text-align: center;

  p {
    display: inline-block;

    border-bottom: ${(props) => (props.$isOpen ? "1px solid #fff" : "none")};
    /* border-bottom: 1px solid #fff; */

    cursor: pointer;
  }
`;

const ContainerSC = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 1.5rem;

  @media (max-width: 1270px) {
    gap: 3rem;
  }

  @media (max-width: 1060px) {
    gap: 1rem;
  }
`;

const ButtonSC = styled("button")`
  position: absolute;
  left: 1.56rem;
  top: -1.4rem;

  z-index: 500;
  /* display: none; */

  background: none;
  background-image: url("contact_support.svg");
  background-repeat: no-repeat;
  background-position: center center;

  cursor: pointer;

  width: 2.5rem;
  height: 2.5rem;

  @media (min-width: 1305px) {
    display: none;
  }
`;

const FullScreenMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* width: 70vw; */
  width: 22.25rem;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3.5px);
  z-index: 499;

  @media (max-width: 442px) {
    width: 100%;
  }
`;

const ULSC = styled.ul`
  margin: 4.37rem 0 0;
  padding: 0 1.5rem 0 1.56rem;

  color: #fff;
`;

const LISC = styled.li`
  margin-bottom: 0.94rem;

  cursor: pointer;

  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  text-transform: uppercase;
`;

const InvisibleText = styled.p<{ $isOpen: boolean }>`
  color: #cfcfcf;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;

  margin-bottom: 0.94rem;

  display: ${(props) => (props.$isOpen ? "block" : "none")};
`;

const SpanCountSC = styled("span")`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  background: rgba(69, 86, 92, 0.9);
  border-radius: 50%;

  position: absolute;
  left: 0;
  top: -4px;

  height: 16px;
  width: 16px;
`;

const ParagraphSC = styled.p`
  padding: 0 1.5rem 1.5rem 1.56rem;

  color: #fff;
`;

export const useHeaderStyle = () => ({
  HeaderSC,
  WrapperHeaderSC,
  ContainerElementsSC,
  HeaderContactsSC,
  SpanSC,
  ButtonSC,
  ContainerSC,
  FullScreenMenu,
  ULSC,
  LISC,
  InvisibleText,
  SpanCountSC,
  ParagraphSC,
});
