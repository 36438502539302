import { create } from "zustand";

import { Store, Card } from "./type";

const initialData = JSON.parse(localStorage.getItem("data") || "{}")?.data;

const useStore = create<Store>((set) => ({
  legal: true,
  basketVisible: false,
  data: initialData || [],
  cards: 0,
  setLegal: (state) => {
    set({ legal: state });
  },
  addCard: (card) => {
    set((state) => {
      const existingIndex = state.data.findIndex(
        (item) => item.vendor_code === card.vendor_code,
      );
      if (existingIndex !== -1) {
        // Артикул уже существует, увеличиваем количество
        const newData = [...state.data];
        newData[existingIndex] = {
          ...newData[existingIndex],
          quantity: newData[existingIndex].quantity + 1,
        };
        return { data: newData };
      } else {
        // Артикул не существует, добавляем новый элемент
        return { data: [...state.data, { ...card, quantity: 1 }] };
      }
    });
  },
  increase: () => set((state) => ({ cards: state.cards + 1 })),
  setQuantity: (newQuantity, item) => {
    set((state) => {
      const newData = state.data.map((dataItem) => {
        if (dataItem.vendor_code === item.vendor_code) {
          return {
            ...dataItem,
            quantity: newQuantity, // Используем новое число
          };
        }
        return dataItem;
      });
      return { data: newData };
    });
  },
  increaseQuantity: (item) => {
    set((state) => {
      const newData = state.data.map((dataItem) => {
        if (dataItem.vendor_code === item.vendor_code) {
          return {
            ...dataItem,
            quantity: dataItem.quantity + 1,
          };
        }
        return dataItem;
      });
      return { data: newData };
    });
  },
  decreaseQuantity: (item) => {
    set((state) => {
      const newData = state.data
        .map((dataItem) => {
          if (dataItem.vendor_code === item.vendor_code) {
            const newQuantity = dataItem.quantity - 1;
            if (newQuantity <= 0) {
              // Если количество достигло 0, удаляем элемент из массива
              return null;
            } else {
              return {
                ...dataItem,
                quantity: newQuantity,
              } as Card; // Приведение типа к Card
            }
          }
          return dataItem;
        })
        .filter(Boolean) as Card[]; // Приведение типа к Card[]
      return { data: newData };
    });
  },
  removeItem: (item) => {
    set((state) => ({
      data: state.data.filter(
        (dataItem) => dataItem.vendor_code !== item.vendor_code,
      ),
    }));
  },
  removeAll: () => {
    set({ cards: 0 });
    set({ data: [] });
    localStorage.removeItem("data");
  },
}));

useStore.subscribe((data) => {
  localStorage.setItem("data", JSON.stringify(data));
});

export default useStore;
