import React from "react";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import InputUI from "UI/InputUI/InputUI";
import { InputSC } from "../../../UI/InputUI/style";

const InputNameBasket = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      defaultValue={""}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            styleComponents={{ InputStyle: InputFullName }}
            inputProps={{ placeholder: "Ф.И.О *" }}
          />
        );
      }}
    />
  );
};

const InputFullName = styled(InputSC)`
  border: 1px solid #000;

  box-shadow: none;

  height: 3rem;
  width: 100%;
  border-radius: 0;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2.5px);

  padding: 0.75rem 1.5rem;

  color: #000;
  text-overflow: ellipsis;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  &::placeholder {
    font-weight: 400;
    line-height: 1.25rem;

    font-size: 0.875rem;
    font-style: normal;
  }

  @media (max-width: 768px) {
    max-width: none;
  }
`;

export default React.memo(InputNameBasket);
