import React, { useEffect } from "react";
import { createPortal } from "react-dom";

import { useRequisitesStyles } from "./useRequisitesStyles";
import { DISABLE_PAYMENT } from "../../../lib/constants/constants";

type ModalState = "requisites" | "legalInfo" | "";

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const Requsites = ({ isOpen, className = "", handleClose }: IModalUIProps) => {
  const onKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose("")();
    }
  };

  const onClickOverlay = (e: React.MouseEvent<HTMLElement>) => {
    handleClose("");

    if ((e.target as HTMLElement).classList.contains("req")) {
      handleClose("")();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      window.addEventListener("keydown", onKey);
    } else {
      document.body.style.overflowY = "scroll";
      window.removeEventListener("keydown", onKey);
    }
    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <ModalContainerSC onClick={onClickOverlay} className={className}>
        <ContainerSC>
          <ReplyIconSC onClick={handleClose("")} src="ReplyIcon.svg" />
          <TextSC>
            <p>ИП Ребрик Николай Георгиевич</p>
            <p>Телефон: +7 (982) 357-85-17</p>
            <p>Режим работы: Понедельник-Пятница, с 9:00 до 17:00</p>
            <p>
              Юридический адрес: 456512, г. Челябинск, п. Красное Поле, ул.
              Авиаторов, дом 5 кв. 126
            </p>
            <p>
              Фактический адрес: 456512, г. Челябинск, п. Красное Поле, ул.
              Авиаторов, дом 5 кв. 126
            </p>
            <p>Адрес склада: г. Челябинск, Троицкий тракт, дом 21Г, корпус 3</p>
            <p>Email: NikolyaRebrik@yandex.ru</p>
            <p>ИНН: 744815067513</p>
            <p>ОГРНИП: 323745600116045</p>
          </TextSC>
          <TitleSC>Политика возврата</TitleSC>
          <TextSC>
            <b>
              <i>Отказ от заказа и возврат товара</i>
            </b>
            <br />
            <br />
            <p>
              — Отказ от заказа до момента его получения Вы можете отказаться от
              заказа заполнив форму обратной связи, или обратившись по телефону:
              +7 (982) 357-85-17.
            </p>
            <br />{" "}
            <p>
              — Возврат товара надлежащего качества Вы можете вернуть товар
              надлежащего качества в момент самовывоза со склада, расположенного
              по адресу: Челябинск, Троицкий тракт, дом 21Г, корпус 3.
              Необходимо предъявить товар в ненарушенной оригинальной упаковке
              производителя (в том числе должна быть не нарушена упаковка,
              сохранены товарный вид и потребительские свойства).
            </p>{" "}
            <br />
            <p>
              — Возврат товара ненадлежащего качества при обнаружении недостатка
              (несоответствия требованиям к качеству) товара Вы можете в течение
              14 календарных дней обратиться по телефону: +7 (982) 357-85-17.
            </p>{" "}
            <br />
            <p>
              При оформлении возврата любого товара Вам необходимо предъявить
              следующие документы: фискальный чек или счет-фактуру.
            </p>{" "}
            <br />
            <b>
              <i>Возврат денежных средств</i>
            </b>{" "}
            <br /> <br />
            <p>
              — Отказ от товара до получения если Вы отказались от заказа до
              момента получения, предварительно оплатив его банковской картой на
              сайте, денежные средства будут возвращены от 5 до 30 рабочих дней
              на банковскую карту, с которой был оплачен заказ.
            </p>{" "}
            <br />
            <p>
              — Отказ от товара после получения в случае оплаты заказа
              наличными, денежные средства будут возвращены Вам непосредственно
              при оформлении возврата. При оплате банковской картой возврат
              денежных средств осуществляется в течение 10 рабочих дней на
              банковскую карту, с которой был оплачен заказ.
            </p>{" "}
            <br />
            <p>
              — Возврат денежных средств при типе оплаты «Банковский перевод» 1.
              В случае если Ваш заказ предоплачен, и Вы желаете отказаться от
              товара, либо обнаружили фактическое отсутствие товара в
              поставленном заказе в момент самовывоза, Вам следует указать в
              графе товарной накладной «Товар» позиции, от принятия которых Вы
              отказываетесь/ фактически отсутствуют: количество, сумма, код
              причины отказа. Во всех экземплярах товарной накладной указать,
              каким образом удобнее получить возврат денежных средств: в счет
              платежа по следующему заказу или переводом на расчетный счет (не
              дожидаясь размещения следующего заказа).
              <br /> <br /> 2. Направить письмо по электронной почте в адрес
              поддержки сайта NikolyaRebrik@yandex.ru с пожеланием о возврате
              денежных средств на расчетный счет. Срок рассмотрения заявки на
              возврат денежных средств составляет 3 дня. Возврат денежных
              средств осуществляется на ту же банковскую карту, с которой
              производился платеж. Возврат денежных средств на карту
              осуществляется в срок от 5 до 30 банковских дней, в зависимости от
              Банка, которым была выпущена банковская карта.
            </p>
          </TextSC>
          <TitleSC>Условия доставки товаров</TitleSC>
          <TextSC>
            Самовывоз со склада адресу г. Челябинск, Троицкий тракт, дом 21Г,
            корпус 3.
          </TextSC>
          {DISABLE_PAYMENT === "false" && (
            <>
              <TitleSC>Правила оплаты</TitleSC>
              <TextSC>
                <p>
                  К оплате принимаются платежные карты: VISA Inc, MasterCard
                  WorldWide, МИР.
                </p>
                <br />
                <p>
                  Для оплаты товара банковской картой при оформлении заказа в
                  интернет-магазине выберите способ оплаты: банковской картой.
                </p>
                <br />
                <p>
                  При оплате заказа банковской картой, обработка платежа
                  происходит на авторизационной странице банка, где Вам
                  необходимо ввести данные Вашей банковской карты:
                </p>
                <br />
                <p>1. Тип карты</p>
                <p>2. Номер карты</p>
                <p>3. Срок действия карты (указан на лицевой стороне карты)</p>
                <p>
                  4. Имя держателя карты (латинскими буквами, точно также как
                  указано на карте)
                </p>
                <p>5. CVC2/CVV2 код</p>
                <br />
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ maxWidth: "320px" }}
                    src="/reqCard.png"
                    alt=" Подсказка по оплате."
                  />
                </div>
                <br />
                <br />
                <p>
                  Если Ваша карта подключена к услуге 3D-Secure, Вы будете
                  автоматически переадресованы на страницу банка, выпустившего
                  карту, для прохождения процедуры аутентификации. Информацию о
                  правилах и методах дополнительной идентификации уточняйте в
                  Банке, выдавшем Вам банковскую карту.
                  <br />
                  <br />
                  Безопасность обработки интернет-платежей через платежный шлюз
                  банка гарантирована международным сертификатом безопасности
                  PCI DSS. Передача информации происходит с применением
                  технологии шифрования TLS. Эта информация недоступна
                  посторонним лицам.
                </p>
              </TextSC>
            </>
          )}
        </ContainerSC>
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose("")} />,
        document.querySelector("#root") as HTMLElement,
      )}
    </>
  ) : null;
};

const {
  ContainerSC,
  ModalBackdoorSC,
  ModalContainerSC,
  ReplyIconSC,
  TextSC,
  TitleSC,
} = useRequisitesStyles();

export default React.memo(Requsites);
