import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useLegalInfoStyles } from "./useLegalInfoStyles";
import useStore from "../../../store";
import { TITLE } from "../../../lib/constants/constants";

type ModalState = "requisites" | "legalInfo" | "";

interface IModalUIProps {
  isOpen: boolean;
  handleClose: (arg: ModalState) => () => void;
  className?: string;
}

const LegalInfo = ({ isOpen, className = "", handleClose }: IModalUIProps) => {
  const [offer, setOffer] = useState(false);

  const { legal } = useStore();
  const { setLegal } = useStore();

  const onKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose("")();
      setOffer(false);
    }
  };

  const onClickOverlay = (e: React.MouseEvent<HTMLElement>) => {
    handleClose("");

    if ((e.target as HTMLElement).classList.contains("info")) {
      handleClose("")();
      setOffer(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      window.addEventListener("keydown", onKey);
    } else {
      document.body.style.overflowY = "scroll";
      window.removeEventListener("keydown", onKey);
    }
    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <ModalContainerSC onClick={onClickOverlay} className={className}>
        {!offer && (
          <>
            <ContainerSC>
              <NavWrapperSC>
                <button onClick={() => setOffer(true)}>Договор оферты</button>
                <ReplyIconSC
                  onClick={() => {
                    handleClose("")();
                    setOffer(false);
                  }}
                  src="ReplyIcon.svg"
                />
              </NavWrapperSC>
              <TitleSC>Согласие на обработку персональных данных.</TitleSC>
              <TextSC>
                <p>
                  Настоящим я даю свое согласие <br />
                  ИП Ребрику Николаю Георгиевичу
                  <br />
                  (ОГРН 323745600116045, ИНН 744815067513), <br />
                  Адрес: Челябинск, Троицкий тракт, дом 21Г, корпус 3
                  <br />
                  (далее – Оператор) на обработку Оператором (включая получение
                  от меня и/или от любых третьих лиц, с учетом требований
                  действующего законодательства Российской Федерации) моих
                  персональных данных и подтверждаю, что, давая такое согласие,
                  я действую своей волей и в своем интересе.
                </p>
                <br />{" "}
                <p>
                  Согласие дается мною для целей заключения с Оператором и/или
                  его партнерами по лицензионным договорам любых договоров,
                  направленных на реализацию (продажу) мне или другим лицам
                  товаров, а также и иных сделок, совершения иных действий,
                  порождающих юридические последствия в отношении меня или
                  других лиц, предоставления мне информации об оказываемых
                  Оператором и/или его партнерами по лицензионным договорам
                  услугах и реализуемых товарах и распространяется на следующую
                  информацию: мои фамилия, имя, отчество, год, месяц, дата и
                  место рождения, адрес и любая иная информация, относящаяся к
                  моей личности, доступная либо известная в любой конкретный
                  момент времени Оператору (далее - «Персональные данные»),
                  предусмотренная Федеральным законом от 27.07.2006 N 152-ФЗ «О
                  персональных данных».
                </p>{" "}
                <br />
                <p>
                  Настоящим я признаю и подтверждаю, что в случае необходимости
                  предоставления Персональных данных для достижения указанных
                  выше целей третьим лицам, а равно как при привлечении третьих
                  лиц к оказанию услуг, реализации товаров в моих интересах,
                  передачи Оператором принадлежащих ему функций и полномочий
                  иному лицу, Оператор вправе в необходимом объеме раскрывать
                  для совершения вышеуказанных действий информацию обо мне лично
                  (включая мои Персональные данные) таким третьим лицам, их
                  агентам и иным уполномоченным ими лицам, а также предоставлять
                  таким лицам соответствующие документы, содержащие такую
                  информацию.
                </p>{" "}
                <br />
                <p>
                  Также настоящим признаю и подтверждаю, что настоящее согласие
                  считается данным мною любым третьим лицам, указанным выше, с
                  учетом соответствующих изменений, и любые такие третьи лица
                  имеют право на обработку Персональных данных на основании
                  настоящего согласия при условии соблюдения требований
                  законодательства (Федеральный закон от 27.07.2006 N 152-ФЗ «О
                  персональных данных»).
                </p>{" "}
                <br />
                <p>
                  Настоящее согласие дается мной, в том числе, для целей
                  продвижения на рынке (в том числе путем осуществления прямых
                  контактов со мной с помощью средств связи, включая почтовые
                  отправления, телефонную связь, электронные средства связи, в
                  том числе SMS-сообщения, факсимильную связь и другие средства
                  связи) продуктов (услуг) ИП Ребрика Николая Георгиевича,
                  совместных продуктов ИП Ребрика Николая Георгиевича и третьих
                  лиц, продуктов (товаров, работ, услуг) третьих лиц.
                </p>{" "}
                <br />
                <p>
                  Настоящее согласие предоставляется на осуществление любых
                  действий в отношении моих персональных данных, которые
                  необходимы или желаемы для достижения указанных выше целей,
                  включая, без ограничения: сбор, систематизацию, накопление,
                  хранение, уточнение (обновление, изменение), использование,
                  распространение (в том числе передача), обезличивание,
                  блокирование, уничтожение, трансграничную передачу
                  Персональных данных, а также осуществление любых иных действий
                  с моими Персональными данными с учетом действующего
                  законодательства. Обработка Персональных данных осуществляется
                  Оператором с применением следующих основных способов (но, не
                  ограничиваясь ими): хранение, запись на электронные носители и
                  их хранение, составление перечней, маркировка.
                </p>{" "}
                <br />
                <br />
                <p>
                  Настоящее согласие дается на срок действия отдельно
                  заключенного Договора и в течение 50 (пятидесяти) лет с даты
                  прекращения или исполнения Договора, в соответствии с
                  действующим законодательством России.
                </p>{" "}
                <br />
                <p>
                  Отзыв согласия может быть произведен в письменной форме не
                  ранее даты прекращения Договора/даты исполнения обязательств в
                  соответствии с Договором. При этом Оператор хранит
                  персональные данные в течение срока хранения документов,
                  установленного действующим законодательством России, а в
                  случаях, предусмотренных законодательством, передает
                  уполномоченным на то нормативно-правовыми актами органам
                  государственным власти. Я согласен с тем, что по моему
                  письменному требованию уведомление об уничтожении персональных
                  данных будет вручаться мне (моему представителю) по месту
                  нахождения Оператора.
                </p>{" "}
                <br />
                <p>
                  При предоставлении мною персональных данных иных лиц, я
                  гарантирую, что согласие вышеуказанных лиц на предоставление
                  их персональных данных мною получено и несу ответственность в
                  случае предъявления каких-либо претензий к ИП Ребрику Николаю
                  Георгиевичу вследствие несоблюдения данного условия. Я
                  непосредственно, либо через своего представителя, предоставляя
                  информацию о своих абонентских номерах подвижной (мобильной)
                  связи, а также абонентских номерах иных лиц или их
                  уполномоченных представителей, даю свое согласие и гарантирую
                  наличие согласия владельцев и пользователей абонентских
                  номеров на получение голосовых, автоматических голосовых и/или
                  смс-уведомлений и/или иных уведомлений с помощью электронных
                  средств связи в пользу ИП Ребрика Николая Георгиевича и
                  указанных им выше третьих лиц, а также подтверждаю наличие
                  желания владельцев и пользователей этих абонентских номеров
                  получать вышеуказанные уведомления и гарантирую, что согласие
                  всех лиц, контакты которых я предоставил, на предоставление их
                  контактов и получение ими уведомлений, мною получено и несу
                  ответственность в случае предъявления каких-либо претензий ИП
                  Ребриком Николаем Георгиевичем вследствие несоблюдения данного
                  условия.
                </p>{" "}
                <br />
                <p>
                  Я понимаю, что мое согласие с условиями Договора купли-продажи
                  товара дистанционным способом, Пользовательским соглашением,
                  Ограничением на использование информации, Сообщением об
                  авторских правах, Политикой конфиденциальности, Согласием на
                  обработку персональных данных, в соответствии с текстом
                  вышеуказанных документов, опубликованных на Сайте, будет
                  выражено путем проставления отметки напротив графы "Согласен"
                  при регистрации на сайте или в любой момент пользования сайтом
                  при отдельном запросе. В случае моего не согласия с
                  вышеуказанными правилами и документами, я обязуюсь прекратить
                  использование сайта и сервисов, принадлежащих ИП Ребрику
                  Николаю Георгиевичу используя функцию "Удаления регистрации"
                  или обращения в офис регистрации.
                </p>{" "}
                <br />
                <div style={{ display: "flex", gap: "8px" }}>
                  <input
                    type="checkbox"
                    id="legalInfo"
                    defaultChecked={legal}
                    onClick={() => setLegal(!legal)}
                    style={{ cursor: "pointer", width: "16px" }}
                  />
                  <label htmlFor="legalInfo">
                    Согласен на обработку персональных данных
                  </label>
                </div>
              </TextSC>
            </ContainerSC>
          </>
        )}
        {offer && (
          <ContainerSC>
            <NavWrapperSC>
              <button onClick={() => setOffer(false)}>
                Персональные данные
              </button>
              <ReplyIconSC
                onClick={() => {
                  handleClose("")();
                  setOffer(false);
                }}
                src="ReplyIcon.svg"
              />
            </NavWrapperSC>
            <TitleSC>
              Публичная оферта о заключении договора оказания услуг.
            </TitleSC>
            <TextSC>
              <p>
                Настоящий документ является предложением ИП «Ребрик Николай
                Георгиевич» (далее – «Компания») в адрес зарегистрированных и
                незарегистрированных пользователей (далее – «пользователи»,
                «вы») сайта, расположенного на домене https://{TITLE}/ и всех
                его поддоменах (далее – «{TITLE}») заключить рамочный договор
                оказания услуг (далее – «Договор») на указанных ниже условиях
                (далее – «Оферта»).
              </p>
              <br />{" "}
              <p>
                Термины и определения, применяемые в настоящем Договоре термины
                и определения используются в следующем их значении:
              </p>
              <br />{" "}
              <p>
                «Сайт» одна или несколько логически связанных между собой
                веб-страниц, объединенных общей тематикой, расположенный под
                одним адресом, доступный пользователем с помощью интернет
              </p>
              <br />{" "}
              <p>
                «Товары\Услуги» ассортимент продукции или услуг, размещенный на
                сайте {TITLE}, предназначенный для реализации для физическим или
                юридическим лицам путем оформления заказа онлайн
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>1. Предмет Договора</i>
                </b>
                <br />
                <br />
                1.1. Договор определяет общие условия обязательственных
                отношений, возникающих между Компанией и Пользователем при
                оказании Компанией реализации товаров с помощью сайта {TITLE}
              </p>
              <br />{" "}
              <p>
                1.2. Заключаемый Договор представляет собой договор с открытыми
                условиями. Существенные условия каждой совершаемой на {TITLE}{" "}
                сделки по оказанию Услуг формируются онлайн с помощью интерфейса{" "}
                {TITLE}, посредством которого Пользователь выбирает интересующий
                его товар.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>2. Заключение Договора. Заказ Услуг</i>
                </b>
                <br />
                <br />
                2.1. Оферта считается акцептованной Пользователем, а Договор
                между Компанией и Пользователем, заключенным с момента оплаты
                пользователем товара по соответствующей сделке (в этом случае
                она совершается одновременно с заключением Договора) или с
                момента внесения аванса в счет оплаты заказываемых в будущем
                товаров. Условия заключенного Договора применяются ко всем
                последующим сделкам между Пользователем и Компанией. Договор
                может быть заключен только с Пользователем, являющимся
                дееспособным физическим лицом либо юридическим лицом или
                индивидуальным предпринимателем.
              </p>
              <br />{" "}
              <p>
                2.2. Каждый заказ конкретных товаров в рамках Договора является
                самостоятельной сделкой. Сделка во исполнение настоящего
                Договора может быть заключена в отношении товаров, доступных для
                заказа и оплаты на {TITLE} в режиме онлайн.
              </p>
              <br />{" "}
              <p>
                2.3. Оплата заказа признается акцептом Пользователя предложения
                о заключении сделки на согласованных сторонами условиях.
              </p>
              <br />{" "}
              <p>
                2.4. Пользователь оформляет каждый заказ товаров в соответствии
                с условиями действующей редакции Оферты и условиями
                соответствующего сервиса. Условия всех сервисов {TITLE},
                опубликованные на {TITLE} в момент заказа товаров, являются
                неотъемлемой частью оферты.
              </p>
              <br />{" "}
              <p>
                2.5. Совершая акцепт Оферты, Пользователь выражает согласие с
                тем, что факт выполнения определенных действий на {TITLE}, в том
                числе с использованием профессиональных технических
                инструментов, выполнение команд через интерфейс {TITLE} (нажатие
                кнопок, клики по ссылкам), совершение платежа и иные подобные
                действия означают ваше волеизъявление в отношении заказа товаров
                в соответствии с указанными на {TITLE} параметрами товаров и их
                ценой.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>3. Общие условия оказания Услуг</i>
                </b>
                <br />
                <br />
                3.1. Услуги оказываются на территории Российской Федерации.
              </p>
              <br />{" "}
              <p>
                3.2. При оплате заказа банковской картой обработка платежа
                происходит на защищенной странице процессинговой системы,
                которая прошла международную сертификацию. Это значит, что
                конфиденциальные данные Пользователя (реквизиты карты,
                регистрационные данные и др.) не поступают в Компанию, их
                обработка полностью защищена, и никто не может получить
                персональные и банковские данные Пользователя.
              </p>
              <br />{" "}
              <p>
                3.3. Предоставляемые на {TITLE} товары могут изменяться,
                дополняться, обновляться, в связи с чем их использование
                предлагается в режиме «как есть», то есть в том виде и объеме, в
                каком они предоставляются Компанией в момент обращения
                Пользователя к услугам и сервисам {TITLE}.
              </p>
              <br />{" "}
              <p>
                3.4. Компания вправе направлять Пользователю по имеющимся у нее
                контактным данным уведомления, связанные с исполнением
                настоящего Договора или любой из заключенных в рамках Договора
                сделок, включая оповещения о статусе оказания Услуги, способах
                продления, изменениях условий оказания Услуг и иные
                информационные сообщения, посредством смс- и push-сообщений,
                электронной почты, звонков и сообщений в мессенджерах и
                телефонных звонков.
              </p>
              <br />{" "}
              <p>
                3.5. Пользователь обязуется соблюдать при пользовании услугами
                требования действующего законодательства Российской Федерации,
                положения Договора, соответствующие условия выбранной Услуги или
                сервиса и все иные правила {TITLE}.
              </p>
              <br />{" "}
              <p>
                3.6. По требованию Компании Пользователь обязан предоставить
                информацию и документы, необходимые для идентификации
                пользователя в качестве стороны Договора, в частности при
                направлении в адрес Компании заявлений, запросов и прочих
                обращений, связанных с оказанием Услуг на {TITLE}. В целях
                идентификации частных пользователей Компанией могут быть
                запрошены ФИО, серия и номер паспорта, скан-копия второй и
                третьей страницы паспорта (второй разворот), адрес электронной
                почты и номер телефона пользователя, используемые для доступа к
                профилю. Кроме того, Компания вправе самостоятельно проводить
                верификацию Пользователя в качестве стороны по Договору путем
                проведения сверки указанных Пользователем в профиле реквизитов с
                реквизитами плательщика по Договору, а также иными способами.
              </p>
              <br />{" "}
              <p>
                3.7. Компания, является оператором персональных данных,
                осуществляет обработку персональных данных Пользователя с целью
                исполнения договора согласно Политике конфиденциальности,
                размещенной на сайте {TITLE}.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>4. Стоймость и оплата товаров</i>
                </b>
                <br />
                <br />
                4.1. Стоимость выбранных товаров в российских рублях
                отображается в интерфейсе {TITLE} при оформлении или оплате
                заказа, если иное не предусмотрено условиями соответствующего
                сервиса или Услуги.
              </p>
              <br />{" "}
              <p>
                4.2. Стоимость включает НДС 20%, уплачиваемый на территории РФ.
              </p>
              <br />{" "}
              <p>
                4.3. Оплата товаров осуществляется на условиях предоплаты по
                ценам, действующим на момент совершения платежа.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>5. Способы оплаты товаров</i>
                </b>
                <br />
                <br />
                5.1. Пользователь, являющийся юридическим лицом или
                индивидуальным предпринимателем, может осуществлять оплату
                товаров по Договору безналичным переводом денежных средств на
                основании выставленного счета. Компания вправе установить
                минимальный объем товаров, оплачиваемых данным способом.
                Допускается также оплата с использованием корпоративной
                банковской карты, оформленной на соответствующее юридическое
                лицо или индивидуального предпринимателя.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>6. Возврат денежных средств</i>
                </b>
                <br />
                <br />
                6.1. После оплаты товара пользователь имеет право в течение 24
                часов вернуть денежные средства, написав в службу поддержки,
                контакты которой размещены на странице {TITLE} (раздел
                покупателям), с адреса электронной почты, указанной при
                регистрации либо оплате, письмо с просьбой сделать возврат,
                указав мотивированную причину отказа. Если подобной заявки в
                течении 24 часов после оплаты товара не поступило, Компания
                оставляет за собой право не возвращать оплаченную сумму.
              </p>
              <br />{" "}
              <p>
                6.2. Компания оставляет за собой право отказать в частичном
                возврате денежных средств без объяснения причин и рассматривает
                это в частном порядке по письменному заявлению от Пользователя.
              </p>
              <br />{" "}
              <p>
                6.3. Ответственность за получение любых документов (в том числе
                актов, сообщений, уведомлений) вышеуказанными способами лежит на
                Пользователе. Компания не несет ответственности за задержку или
                неполучение Пользователем документов, если это явилось
                результатом неисправности систем связи, действия/бездействия
                провайдеров, операторов связи, утраты Пользователем доступа к
                профилю или иных обстоятельств, находящихся вне зоны контроля
                Компании.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>7. Ответственность</i>
                </b>
                <br />
                <br />
                7.1. Компания обязуется предпринимать все разумные усилия для
                надлежащего оказания Услуг, однако не отвечает и не компенсирует
                убытки пользователя в случае, если Пользователь не может
                воспользоваться Услугами по следующим причинам:
                <br />
              </p>
              <p style={{ padding: "8px 0 0 16px" }}>
                • технологические неисправности каналов связи общего
                пользования, посредством которых осуществляется доступ к
                товарам, утраты Пользователем доступа в интернет по любой
                причине, ошибки, пропуски, перерывы в работе или передаче
                данных, дефекты линий связи и иные технические сбои;
                <br />• несанкционированное вмешательство в работу {TITLE}{" "}
                третьих лиц, включая хакерские, DDoS-атаки, действия вирусных
                программ и иные нарушения работы {TITLE};
                <br />• при наступлении обстоятельств непреодолимой силы;
              </p>
              <br />{" "}
              <p>
                7.2. В случае причинения убытков Пользователю по вине Компании
                ответственность определяется в порядке, предусмотренном
                законодательством РФ. При этом ответственность Компании перед
                Пользователями, использующими {TITLE} для предпринимательской и
                иной профессиональной деятельности, определяется в размере, не
                превышающем стоимость заказанной и оплаченной Пользователем, но
                не полученной по вине Компании.
              </p>
              <br />{" "}
              <p>
                7.3. Компания не отвечает перед Пользователем за убытки,
                возникшие у Пользователя не по вине Компании, в том числе в
                связи с нарушением Пользователем правил {TITLE}.
              </p>
              <br />{" "}
              <p>
                7.4. Компания не несет ответственности за перевод или
                поступление денежных средств Пользователя в оплату товаров.
                Безопасность, конфиденциальность, а также иные условия
                использования выбранных Пользователем способов оплаты
                определяются соглашениями между Пользователем и соответствующими
                организациями по приему платежей.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>8. Рассмотрение претензий</i>
                </b>
                <br />
                <br />
                8.1. Отношения Сторон, вытекающие из настоящего Договора, а
                также тесно связанные с ними, регулируются действующим
                законодательством РФ.
              </p>
              <br />{" "}
              <p>
                8.2. Споры, возникшие в рамках настоящего Договора
                рассматриваются и разрешаются в арбитражном суде г. Челябинска.
              </p>
              <br />{" "}
              <p>
                8.3. До обращения в суд Пользователь с профилем юридического
                лица или индивидуального предпринимателя должен соблюсти
                внесудебный порядок разрешения споров, направив в адрес Компании
                письменную претензию, с приложением документов, подтверждающих
                обстоятельства и требования, на которые ссылается Пользователь.
                Срок рассмотрения таких претензий составляет не более 30 дней с
                момента получения претензии Компанией.
              </p>
              <br />{" "}
              <p>
                <b>
                  <i>9. Прочие условия</i>
                </b>
                <br />
                <br />
                9.1. Договор вступает в силу с момента его заключения в порядке,
                указанном в разделе 2 Оферты, и действует в течение
                неопределенного срока до момента его прекращения в соответствии
                с Договором и законодательством.
              </p>
              <br />{" "}
              <p>
                9.2. Компания вправе изменять и/или дополнять Оферту в той мере,
                в какой это допускается законодательством, а также отозвать
                Оферту в любое время. Датой изменения Оферты является дата
                публикации на {TITLE} новой редакции Оферты. Вы должны
                ознакомиться с действующей редакцией Оферты, размещенной на{" "}
                {TITLE}, и с условиями каждой сделки до заключения Договора и
                совершения сделки.
              </p>
              <br />{" "}
              <p>
                9.3. Заказ товаров и совершение Пользователем сделки в рамках
                Договора после вступления в силу изменений в Оферте означает,
                что стороны пришли к согласию о внесении соответствующих
                изменений и/или дополнений в Договор. Такие изменения в Договоре
                не применяются к товарам, заказанным и оплаченным Пользователем
                до вступления в силу изменений в Оферте.
              </p>
            </TextSC>
          </ContainerSC>
        )}
      </ModalContainerSC>
      {createPortal(
        <ModalBackdoorSC onClick={handleClose("")} />,
        document.querySelector("#root") as HTMLElement,
      )}
    </>
  ) : null;
};

const {
  ContainerSC,
  ModalBackdoorSC,
  ModalContainerSC,
  ReplyIconSC,
  TextSC,
  TitleSC,
  NavWrapperSC,
} = useLegalInfoStyles();

export default React.memo(LegalInfo);
