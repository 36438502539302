import { useEffect, useRef, useState } from "react";

import { useProductsStyles } from "./ProductsStyles";
import ProductCard from "components/ProductCard";
import { useProducts } from "./useProducts";
import FullVisionCard from "components/ProductCard/FullVision";
import useStore from "../../store";

const Products = () => {
  const { data } = useStore();
  const { divRef, selectedProduct, setSelectedProduct, cards } = useProducts();

  const Elements = cards.map((item, index) => {
    const isAdded = data.some((itemBask) => itemBask.name === item.title);

    return <ProductCard key={index} isAdded={isAdded} {...item} />;
  });

  return (
    <>
      <ProductsSC>
        <ProductsTitleSC id="Products">Продукция</ProductsTitleSC>
        <ProductContainerSC ref={divRef}>{Elements}</ProductContainerSC>
      </ProductsSC>
    </>
  );
};

const { ProductsSC, ProductsTitleSC, ProductContainerSC } = useProductsStyles();

export default Products;
