import { useState, useEffect } from "react";
import useStore from "../../store";

export const useHeader = () => {
  const { totalCount } = useStore((state) => {
    const dataLength = state.data.length; // Длина массива data

    // Подсчет общего количества элементов (сумма quantity всех элементов)
    const totalCount = state.data.reduce(
      (total, item) => total + item.quantity,
      0,
    );

    return {
      dataLength,
      totalCount,
    };
  });
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenAbout, setisOpenAbout] = useState(false);
  const [isOpenInvisibleText, setIsOpenInvisibleText] = useState(false);

  const toggleContact = () => {
    setIsOpenContact(!isOpenContact);
  };

  const toggleInvisibleText = () => {
    setIsOpenInvisibleText(!isOpenInvisibleText);
  };

  const toggleAbout = () => {
    setisOpenAbout(!isOpenAbout);
  };

  const closeForm = () => {
    setisOpenAbout(false);
  };

  const closeBurger = () => {
    setIsOpenContact(false);
  };

  useEffect(() => {
    if (isOpenContact) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    const windowSize = () => {
      if (window.innerWidth <= 812) {
      } else {
        setIsOpenContact(false);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, [isOpenContact]);

  return {
    totalCount,
    toggleAbout,
    isOpenContact,
    isOpenAbout,
    toggleContact,
    closeForm,
    isOpenInvisibleText,
    toggleInvisibleText,
    closeBurger,
  };
};
