// @ts-nocheck
import { useState, useEffect, useRef } from "react";

import { useProductCardStyles } from "./ProductCardStyles";

import FullVisionCard from "./FullVision";
import axios from "axios";
import { useCardFull } from "./FullVision/useFullVision";
import { BASE_URL, DISABLE_PAYMENT } from "../../lib/constants/constants";
import styled from "styled-components";
import useStore from "../../store";

const ProductCard = ({
  id,
  image,
  title,
  description,
  price,
  vendor_code,
  isAdded,
}) => {
  const { addCard, increase } = useStore();
  const Cardref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const windowSize = () => {
      if (window.innerWidth >= 1890) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []);

  const handleClose = (e) => {
    setIsOpen(false);
  };

  const handleClick = async () => {
    setIsOpen(true);
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();

    addCard({
      name: title,
      price,
      vendor_code,
      image,
      description: "",
      quantity: 0,
    });
    increase();
  };

  return (
    <>
      <CardSC ref={Cardref} onClick={handleClick}>
        <div style={{ borderRadius: "50%" }}>
          <ImgSC src={`${BASE_URL}/${image}`} />
        </div>
        <InfoContainerSC>
          <TitleSC>{title}</TitleSC>
          <DescriptionSC>{description}</DescriptionSC>
        </InfoContainerSC>
        <NumberContainerSC>
          {/*<VendorCodeSC>{vendor_code}</VendorCodeSC>*/}
          <PriceSC>{price} ₽</PriceSC>
        </NumberContainerSC>
        {!isAdded && <ButtonSC onClick={handleAddToCart}>Добавить</ButtonSC>}
        {isAdded && (
          <AddedBasketSC onClick={handleAddToCart}>В корзине</AddedBasketSC>
        )}
      </CardSC>
      {isOpen && (
        <FullVisionCard
          title={title}
          description={description}
          price={price}
          vendor={vendor_code}
          doClose={handleClose}
        />
      )}
    </>
  );
};

const AddedBasketSC = styled("button")`
  width: 100%;
  min-height: 30px;
  max-height: 30px;
  backdrop-filter: blur(2.5px);
  border-radius: 16px;
  border: none;

  cursor: pointer;

  background: rgba(74, 196, 200, 0.6);
  -webkit-backdrop-filter: blur(2.5px);
  color: #fff;
  font-weight: 600;
  text-align: center;
`;

const ButtonSC = styled("button")`
  width: 100%;

  cursor: pointer;

  min-height: 30px;
  max-height: 30px;
  border-radius: 16px;

  font-size: 14px;
  font-weight: 600;
  color: #fff;

  background: #868181;

  background: rgba(69, 86, 92, 0.5);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  height: 3.25rem;
  line-height: 1.5rem;
`;

const {
  CardSC,
  ImgSC,
  InfoContainerSC,
  NumberContainerSC,
  TitleSC,
  DescriptionSC,
  // VendorCodeSC,
  PriceSC,
} = useProductCardStyles();

export default ProductCard;
