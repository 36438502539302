import { useFormStyles } from "./FooterStyles";

import useFooter from "./useFooter";
import { DISABLE_PAYMENT } from "../../lib/constants/constants";

const Footer = () => {
  const { onClick } = useFooter();
  return (
    <>
      <FooterSC>
        <ContainerSC>
          <SpanSC onClick={onClick}>
            <p>О КОМПАНИИ</p>
          </SpanSC>

          <SpanSC>
            <a href="#formTitle">обратная связь</a>
          </SpanSC>

          <SpanSC>
            <a href="#Products">продукция</a>
          </SpanSC>
        </ContainerSC>
        <ContainerSC>
          <SpanSC>
            <a href="tel:+79823578517">+7 (982) 357-85-17</a>
          </SpanSC>
          <SpanSC>
            <a href="mailto:NikolyaRebrik@yandex.ru">NikolyaRebrik@yandex.ru</a>
          </SpanSC>
        </ContainerSC>
      </FooterSC>
      <TextContainerSC>
        <SubtitleSC>Троицкий тракт, 21г, корпус 3</SubtitleSC>
        <SubtitleSC>
          Индивидуальный предприниматель Ребрик Николай Георгиевич
        </SubtitleSC>
        <SubtitleSC>ИНН: 744815067513</SubtitleSC>
        {DISABLE_PAYMENT === "false" && (
          <ImageContainerSC style={{ marginTop: "1.69rem" }}>
            <img src="/HorizontalLogos.svg" alt="Логотипы платежных систем" />
          </ImageContainerSC>
        )}
      </TextContainerSC>
    </>
  );
};

const {
  FooterSC,
  SpanSC,
  ContainerSC,
  TextContainerSC,
  SubtitleSC,
  ImageContainerSC,
} = useFormStyles();

export default Footer;
