// @ts-nocheck

import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Controller, FormProvider } from "react-hook-form";
import { useFormFeedback } from "../../FormFeedBack/useFormFeedBack";
import InputNameBasket from "./NameInputBasket";
import InputEmailBasket from "./EmailInputBasket";
import useStore from "../../../store";

const BasketForm = ({ handleChangeVisible }) => {
  const { removeAll } = useStore();
  const { method, handleSubmit, onSubmit } = useFormFeedback();
  const [state, setState] = useState(false);

  const { legal } = useStore();
  const { setLegal } = useStore();

  const handleClickOrder = () => {
    setState(true);
    removeAll();

    setTimeout(() => {
      handleChangeVisible();
    }, 2000);
  };

  return (
    <SectionSC>
      {!state && (
        <>
          <TitleSC>
            Для обратного звонка от специалиста оставьте свой номер или почту
          </TitleSC>
          <FormProvider {...method}>
            <FormSC action="#" onSubmit={handleSubmit}>
              <WrapperSC>
                <InputNameBasket />
                <InputEmailBasket />
              </WrapperSC>
              <ButtonSC
                type="submit"
                onClick={handleClickOrder}
                disabled={!legal}>
                Заказать
              </ButtonSC>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "16px",
                }}>
                <input
                  type="checkbox"
                  id="legalInfo"
                  defaultChecked={legal}
                  onClick={() => setLegal(!legal)}
                  style={{ cursor: "pointer", width: "16px" }}
                />
                <span style={{ textAlign: "center" }}>
                  Согласен на обработку персональных данных
                </span>
              </div>
            </FormSC>
          </FormProvider>
        </>
      )}
      {state && (
        <SuccessContainerSC>
          <SuccessTitleSC>Заявка успешно создана</SuccessTitleSC>
        </SuccessContainerSC>
      )}
    </SectionSC>
  );
};

const SuccessContainerSC = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;

const SuccessTitleSC = styled("h4")`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 4rem;

  @media (max-width: 812px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.875rem;

    margin-bottom: 1.38rem;
  }

  @media (max-width: 400px) {
    font-size: 0.9rem;
  }
`;

const WrapperSC = styled("div")`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
`;

const SectionSC = styled("section")`
  height: 100%;

  /* margin-bottom: 8.25rem; */

  @media (max-width: 812px) {
    width: calc(100% - 15px * 2);
    margin: 0 auto;

    padding-bottom: 2.5rem;
  }
`;

const TitleSC = styled("h4")`
  text-align: center;
  font-weight: 500;
  font-size: 18px;

  margin-bottom: 2rem;

  @media (max-width: 812px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.875rem;

    margin-bottom: 1.38rem;
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
`;

const FormSC = styled("form")`
  height: 90%;

  display: flex;
  flex-direction: column;
  gap: 32px;
  //justify-content: center;
  //align-items: center;
  /* flex-wrap: wrap; */

  /* width: 100%; */
  /* width: 89rem; */

  @media (max-width: 812px) {
  }
`;

const InputSC = styled("input")``;

const ButtonSC = styled("button")`
  border-radius: 8px;

  margin: 0 auto;

  background: #75919d;
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  width: 100%;
  max-width: 15rem;
  height: 40px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;

  &:disabled {
    cursor: initial;
    background: gray;
  }
`;

export default BasketForm;
