import styled from "styled-components";

const ProductsSC = styled("section")`
  color: #0c0e20;

  /* width: calc(100% - 172px * 2); */
  /* width: 85%; */
  margin: 0 auto;

  background: #75919d;
  padding-top: 1.25rem;
  padding-bottom: 3rem;
`;

const ProductsTitleSC = styled("h2")`
  color: #fff;

  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;

  margin-bottom: 2.88rem;

  @media (max-width: 1024px) {
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4375rem;

    margin-bottom: 5rem;
  }
  @media (max-width: 1024px) {
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4375rem;

    margin-bottom: 2.81rem;
  }
`;

const ProductContainerSC = styled("div")`
  display: grid;
  margin: 0 auto;
  /* grid-template-columns: repeat(5, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(296px, 1fr)); */
  /* grid-template-columns: repeat(auto-fit, minmax(18.5rem, 1fr)); */
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(4, minmax(0, 1fr));

  width: 75%;

  grid-gap: 3rem;

  justify-items: center;
  align-items: center;

  @media (max-width: 1236px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 560px) {
    //width: 100%;
    width: 90%;
    /* grid-template-columns: repeat(1, 1fr); */
  }
  @media (max-width: 375px) {
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  /* @media (max-width: 1428px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1394px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1078px) {
    grid-template-columns: repeat(2, 1fr);
  } */
  /* @media (max-width: 812px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.51rem;
  }
  @media (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
  } */
`;

export const useProductsStyles = () => ({
  ProductsSC,
  ProductsTitleSC,
  ProductContainerSC,
});
