import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FEEDBACK_URL } from "../../lib/constants/constants";

export const useFormFeedback = () => {
  const method = useForm();
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    subject: "",
  });
  const [text, setText] = useState("Форма обратной связи");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("contact", formData.contact);
    formDataToSend.append("subject", formData.subject);

    const requestOptions = {
      method: "POST",
      body: formDataToSend,
    };

    fetch(FEEDBACK_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setFormData({
          name: "",
          contact: "",
          subject: "",
        });
        setText("Успешно!");
      })
      .catch((error) => setText("Произошла ошибка!"));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setText("Форма обратной связи");
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [text]);

  return { method, formData, text, handleChange, handleSubmit };
};
