import React from "react";
import styled, { css } from "styled-components";
import BasketCard from "./BasketCard";

const BasketElements = ({ data }) => {
  return (
    <ContainerSC>
      {data.map((item) => (
        <BasketCard {...item} />
      ))}
    </ContainerSC>
  );
};

const ContainerSC = styled("ul")`
  display: flex;
  flex-direction: column;
  gap: 24px;

  flex-grow: 1;

  list-style-type: none;
`;

export default BasketElements;
