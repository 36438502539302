import { useEffect, useRef, useState, useCallback } from "react";
import { axiosGears } from "lib/http";
import { IProductCardsProps } from "types/types";

export const useProducts = () => {
  const divRef = useRef(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [cards, setCards] = useState<IProductCardsProps[]>([]);
  const [loadingCard, setLimit] = useState<number>(100);
  // const [isEndCards, setIsEndCards] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const limit = loadingCard > cards.length ? cards.length : loadingCard;
  const isEndCards = loadingCard > cards.length;
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosGears.get("/posts?page=13", {});
      setCards(res.data.posts);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const loader = useRef(null);

  const handleClickMobile = () => {
    if (!(loadingCard > cards.length)) {
      const scrolled = window.scrollY;

      setLimit((prev) => prev + 100);
      window.scrollTo(0, scrolled - 500);
    }
  };
  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading) {
        setLimit((prev) => {
          return prev + 100;
        });
      }
    },
    [loading],
  );
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver, loadingCard]);

  function handleClick() {
    console.log("Элемент div был кликнут!");
  }

  useEffect(() => {
    const div = divRef.current;

    fetchData();

    const windowSize = () => {
      if (window.innerWidth <= 812) {
        div.addEventListener("click", handleClick);
      } else {
        div.removeEventListener("click", handleClick);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return {
    divRef,
    selectedProduct,
    setSelectedProduct,
    handleClickMobile,
    loadingCard,
    isEndCards,
    loading,
    limit,
    cards,
    loader,
  };
};
/* 
export const useProductList = () => {
  return {};
}; */
