import styled from "styled-components";

const MainSC = styled("main")`
  /* height: 100vh; */
  width: 100%;
  background: #75919d;
`;

const MainWrapperSC = styled("div")`
  margin: 0 auto;
  max-width: 2600px;

  height: 100vh;
  background-image: url("Background.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 1440px) {
    background-size: cover;
  }

  @media (max-width: 442px) {
    height: 35rem;
  }
`;

const OuterSC = styled("div")`
  background: rgba(8, 12, 28, 0.62);
  /* height: 100%; */
  height: 880px;
  width: 100%;

  z-index: 4;
`;

const CircleSC = styled("div")`
  /* position: absolute;
  border-radius: 1264px;
  background: rgba(8, 12, 28, 0.62);
  width: 1264px;
  height: 1264px;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%); */

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(8, 12, 28, 0.62); */

  z-index: 2;
`;

const CircleWrapperSC = styled("div")`
  width: 200px;
  height: 200px;
  /* width: 50%; */
  /* height: 50%; */
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;
`;

const CircleColorSC = styled("div")`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(8, 12, 28, 0.62);
  /* background-color: blue; */
`;

export const useMainStyles = () => ({
  MainSC,
  OuterSC,
  MainWrapperSC,
  CircleSC,
  CircleWrapperSC,
  CircleColorSC,
});
