import styled from "styled-components";

const ModalContainerSC = styled("div")`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: modalin 0.7s ease-in-out;
  @keyframes modalin {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
`;

const TitleSC = styled.p`
  color: #001a72;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  text-align: left;

  margin-bottom: 0.62rem;
`;

const ModalBackdoorSC = styled("div")`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: modalBackdoorIn 0.7s ease-in-out;
  @keyframes modalBackdoorIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const ContainerSC = styled.div`
  z-index: 5;
  position: relative;
  width: 50%;
  max-width: 48rem;
  height: 41rem;
  background: #fff;
  padding: 1.16rem;
  border-radius: 24px;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Цвет скролла */
  &::-webkit-scrollbar-thumb {
    background-color: #d6d9e3;
  }

  /* Скрытие канавки скролла */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 1174px) {
    width: 90%;
    max-width: none;
  }
`;

const TextSC = styled.p`
  color: #646776;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 0.62rem;
`;

const ReplyIconSC = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

const NavWrapperSC = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  button {
    color: white;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    background: #646776;
    border-radius: 16px;
    padding: 4px 16px;
  }
`;

export const useLegalInfoStyles = () => ({
  ContainerSC,
  ModalBackdoorSC,
  ModalContainerSC,
  ReplyIconSC,
  TextSC,
  TitleSC,
  NavWrapperSC,
});
