import React from "react";
import styled, { css } from "styled-components";
import useStore from "../../../store";
import { BASE_URL } from "../../../lib/constants/constants";
import { ReactComponent as DeleteIcon } from "../../../assets/icon/deleteIcon.svg";

const BasketCard = (props) => {
  const { removeItem, decreaseQuantity, increaseQuantity, setQuantity } =
    useStore();

  return (
    <li>
      <ContainerSC>
        <OutterSC>
          <ImgSC src={`${BASE_URL}/${props.image}`} />
          <WrapperSC>
            <div>
              <TitleSC>{props.name}f</TitleSC>
              <VendorSC>{props.vendor_code}</VendorSC>
            </div>
            <PriceSC>{props.price} ₽</PriceSC>
          </WrapperSC>
          <ActionWrapperSC>
            <ButtonSC onClick={() => removeItem(props)}>
              <DeleteIcon />
            </ButtonSC>
            <CountContainerSC>
              <SplitButtonSC onClick={() => decreaseQuantity(props)}>
                -
              </SplitButtonSC>
              <CountSC
                type={"number"}
                value={props.quantity}
                onChange={(e) => setQuantity(Number(e.target.value), props)}
                onBlur={(e) => {
                  e.target.value === "0" && removeItem(props);
                }}
              />
              <SplitButtonSC onClick={() => increaseQuantity(props)}>
                +
              </SplitButtonSC>
            </CountContainerSC>
          </ActionWrapperSC>
        </OutterSC>
      </ContainerSC>
    </li>
  );
};

const ContainerSC = styled("article")`
  display: flex;

  width: 100%;
`;

const OutterSC = styled("div")`
  display: flex;
  width: 100%;
`;

const WrapperSC = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const VendorSC = styled("h4")`
  font-size: 12px;
  font-weight: 400;
`;

const ImgSC = styled("img")`
  display: block;

  object-fit: cover;

  width: 100px;
  height: 100px;
  /* height: 15rem; */

  margin-right: 1.5rem;

  @media (max-width: 450px) {
    width: 80px;
    height: 80px;
  }
`;

const ActionWrapperSC = styled("div")`
  margin: 0 14px;

  flex-grow: 1;
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: column;
`;

const ButtonSC = styled("button")`
  cursor: pointer;
  background: none;

  width: 24px;
  height: 24px;

  svg {
    width: 22px;
    height: 22px;

    fill: #000;

    @media (max-width: 500px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 400px) {
      width: 16px;
      height: 16px;
    }
  }
`;

const TitleSC = styled("h6")`
  font-size: 16px;

  flex-grow: 1;

  @media (max-width: 500px) {
    font-size: 12px;

    text-align: left;
    font-weight: 600;
    line-height: 1.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CountContainerSC = styled("div")`
  display: flex;

  flex-direction: column;
  border: 1px solid #000;
  border-radius: 6px;

  @media (max-width: 450px) {
    justify-content: center;
  }
`;

const CountSC = styled("input")`
  text-align: center;

  width: 20px;

  color: #000;

  border: none;

  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &:focus {
    outline: none;
  }
`;

const SplitButtonSC = styled("button")`
  width: 20px;
  height: 20px;

  cursor: pointer;

  font-weight: 600;
  color: #000;
  font-size: 16px;
  background: 0;
  //background-color: #292929;
`;

const PriceSC = styled("p")``;

export default React.memo(BasketCard);
